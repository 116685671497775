








































































































































































import { EventBus } from "@/components/EventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseView } from "@/components/BaseView";
import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { GetBookingResponse, GetAgencyResponse, GetDayScheduleBusRoutesResponse } from "@/services/ClientInterfaces";
import ConversationView from "@/views/Crm/ConversationView.vue";

@Component({
    components: {
        ConversationView,
    },
})
export default class extends BaseView {
    @Prop({ required: true, type: String })
    itemId!: string;

    loading = true;
    item: GetBookingResponse | null = null;
    agency: GetAgencyResponse | null = null;
    routes: GetDayScheduleBusRoutesResponse = [];
    conversationId = "";

    @Watch("itemId", { immediate: true })
    async refresh() {
        this.conversationId = "";
        this.loading = true;

        this.item = await DataClient.Bookings.get({ id: this.itemId });
        this.agency = await DataClient.Agencies.get({ id: this.item.agencyId });

        const routes = await DataClient.DaySchedules.getBusRoutes({ date: this.item.date });
        const pickupInformation = await DataClient.Bookings.getPickupInformation({ id: this.itemId });

        this.routes = [];

        for (const zone of routes) {
            const services: GetDayScheduleBusRoutesResponse[number]["services"] = [];

            for (const service of zone.services) {
                let addService = service.id === pickupInformation.pickupServiceId || service.id === pickupInformation.activityServiceId;
                if (addService) {
                    services.push(service);
                }
            }

            if (services.length) {
                this.routes.push({
                    ...zone,
                    services,
                });
            }
        }

        this.conversationId = this.item.conversationId;
        this.loading = false;
    }

    get formattedDate() {
        const date = this.item?.date || "";
        return Utilities.getDayOfWeekNameYYYYMMDD(date).toLowerCase() + ", " + Utilities.formatDateToDDMMYYYY(date);
    }

    openBooking() {
        this.$emit("open", this.item?.id);
    }

    async openCrm() {
        await DataClient.Crm.open({ bookingId: this.item?.id });
    }

    getMeetingPointLastPickupTime(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        const lastPickupTime = meetingPoint.lastPickupTime;

        if (lastPickupTime) {
            // extract the time (HH:MM) from a the string like " 2023-08-04T11:48:35.433Z"
            return lastPickupTime.substring(11, 16);
        }

        return "";
    }

    getMeetingPointColor(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        const lastPickupTime = this.getMeetingPointLastPickupTime(meetingPoint);

        if (lastPickupTime) {
            if (lastPickupTime > meetingPoint.time) {
                return "red darken-1 white--text";
            }
        }

        return "green white--text";
    }

    getMeetingPointStyle(meetingPoint: GetDayScheduleBusRoutesResponse[number]["services"][number]["meetingPoints"][number]) {
        if (meetingPoint.id === this.item?.meetingPointId) {
            return "border: 3px dotted #2196f3; border-radius: 4px; padding: 4px;";
        }

        return "border: 3px; border-radius: 4px; padding: 4px;";
    }
}
