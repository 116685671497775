



























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    dialog = false;
    resolve: ((value: boolean) => void) | null = null;
    reject: ((value: boolean) => void) | null = null;
    message: string | null = null;
    title: string | null = null;
    options = {
        color: "#2B3848",
        width: 400,
        zIndex: 200,
        noconfirm: false,
    };

    open(title: string, message: string, options?: any) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        if (options) {
            this.options = Object.assign(this.options, options);
        }
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    agree() {
        if (this.resolve) {
            this.resolve(true);
        }

        this.dialog = false;
    }

    cancel() {
        if (this.resolve) {
            this.resolve(false);
        }
        this.dialog = false;
    }
}
