


































































import { EventBus } from "@/components/EventBus";
import { OpenCrmRequest, DataClient } from "@/services/DataClient";
import { GetConversationResponse } from "@/services/ClientInterfaces";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BaseView } from "@/components/BaseView";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Utilities } from "@/services/Utilities";

@Component
export default class extends BaseView {
    @Prop({ required: true, type: String })
    conversationId!: string;

    @Prop({ required: false, default: 0, type: Number })
    refreshToken!: number;

    conversation: GetConversationResponse = {
        id: "",
        messages: [],
        creationDate: "",
    };

    get messages() {
        return this.conversation.messages.slice().reverse();
    }

    async mounted() {
        this.fetchData();
    }

    @Watch("refreshToken")
    @Watch("conversationId")
    async onRefreshTokenChanged() {
        await this.fetchData();
    }

    async fetchData() {
        if (this.conversationId) {
            this.conversation = await DataClient.Conversations.get({ id: this.conversationId });
        } else {
            this.conversation = {
                id: "",
                messages: [],
                creationDate: "",
            };
        }
    }

    // transform the message string to a html string, adding <a> tags to the links
    transformMessage(message: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return message.replace(urlRegex, (url) => {
            return '<a href="' + url + '" target="_blank">' + url + "</a>";
        });
    }

    formatTimestamp(timestamp: string) {
        const date = timestamp.substring(0, 10);
        return `${Utilities.getDayOfWeekNameYYYYMMDD(date)}, ${Utilities.formatTimestamp(timestamp)}`;
    }

    isHtmlMessage(message: string) {
        return message.indexOf("<!") >= 0;
    }

    copyToClipboard(text: string) {
        Utilities.copyToClipboard(text);
        DataClient.Dialogs.toast({
            title: "Copiado al portapapeles",
            color: "gray darken-3",
        });
    }

    resend(text: string) {
        this.$emit("resend", text);
    }

    getStatusIcon(status: string) {
        switch (status) {
            case "sent":
                return "mdi-check";
            case "pending":
                return "mdi-timer-outline";
            case "received":
                return "mdi-check";
            case "failed":
                return "mdi-alert-circle-outline";
            default:
                return "";
        }
    }

    getStatusColor(status: string) {
        switch (status) {
            case "sent":
                return "success";
            case "pending":
                return "orange darken-2";
            case "received":
                return "success";
            case "failed":
                return "error";
            default:
                return "";
        }
    }
}
