


























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {

    @Prop({ default: "Sin título" })
    title?: string;

    @Prop({ default: "Descripción" })
    description?: string;

}
