

























































import { EventBus } from "@/components/EventBus";
import { OpenLogViewerRequest, DataClient } from "@/services/DataClient";
import { SearchLogsResponse } from "@/services/ClientInterfaces";
import { Utilities } from "@/services/Utilities";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BaseView } from "@/components/BaseView";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

interface InternalItem {
    date: string;
    entries: Array<{
        time: string;
        user: string;
        message: string;
        data: string;
    }>;
}

@Component
export default class extends BaseView {
    dialog = false;
    loading = false;

    query = "";
    searching = false;

    selectedType = "";
    selectedId = "";

    searchText = "";

    results: SearchLogsResponse = [];

    mounted() {
        EventBus.$on(DataClient.Logs.OpenLogViewerDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Logs.OpenLogViewerDialogEvent, this.handleOpenEvent);
    }

    async handleOpenEvent(event: OpenLogViewerRequest) {
        if (this.dialog) {
            this.dialog = false;
            return;
        }

        this.results = [];

        this.searchText = "";
        this.loading = true;
        this.dialog = true;

        this.results = await DataClient.Logs.search({
            aggregateId: event.aggregateId,
        });

        this.loading = false;
    }

    get filteredResults(): SearchLogsResponse {
        const normalizedSearchText = Utilities.normalize(this.searchText);
        const filteredResults = this.searchText ? this.results.filter((item) => Utilities.objectIncludes(item, ["user", "message", "data"], normalizedSearchText)) : this.results;

        return filteredResults;
    }

    get grupedResults(): InternalItem[] {
        // group results by date
        const groups: InternalItem[] = [];

        for (const result of this.filteredResults) {
            const group = groups.find((g) => g.date === result.date);

            if (group) {
                group.entries.push({
                    time: result.time,
                    user: result.user,
                    message: result.message,
                    data: result.data,
                });
            } else {
                groups.push({
                    date: result.date,
                    entries: [
                        {
                            time: result.time,
                            user: result.user,
                            message: result.message,
                            data: result.data,
                        },
                    ],
                });
            }
        }

        return groups;
    }

    accept() {
        this.dialog = false;
        this.results = [];

        DataClient.Logs.open(true, true);
    }

    cancel() {
        this.dialog = false;
        this.results = [];

        DataClient.Logs.open(false, true);
    }

    niceDate(date: string): string {
        if (date === Utilities.todayAsYYYYMMDD()) {
            return "Hoy, " + Utilities.getDayOfWeekNameYYYYMMDD(date).toLowerCase() + " " + Utilities.formatDateToDDMMYYYY(date);
        } else if (date === Utilities.yesterdayAsYYYYMMDD()) {
            return "Ayer, " + Utilities.getDayOfWeekNameYYYYMMDD(date).toLowerCase() + " " + Utilities.formatDateToDDMMYYYY(date);
        } else {
            return Utilities.getDayOfWeekNameYYYYMMDD(date) + " " + Utilities.formatDateToDDMMYYYY(date);
        }
    }
}
