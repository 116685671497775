























import { BaseView } from "@/components/BaseView";
import { GetAllMessageTemplatesResponse } from "@/services/ClientInterfaces";
import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component
export default class extends BaseView {
    @Prop({ default: "", required: false, type: String })
    type!: string;

    @Prop({ default: false, required: false, type: Boolean })
    autoSelectFirst!: boolean;

    templates: GetAllMessageTemplatesResponse = [];
    searchText = "";

    get filteredTemplates() {
        return Utilities.sortAndFilter(this.templates, "name", this.searchText, (item) => Utilities.objectIncludes(item, ["name"], this.searchText));
    }

    async mounted() {
        const templates = await DataClient.MessageTemplates.getAll();

        if (this.type) {
            this.templates = templates.filter((x) => x.type === this.type);
        } else {
            this.templates = templates;
        }

        // remove voucher templates
        this.templates = this.templates.filter((x) => x.type !== "voucher");

        if (this.autoSelectFirst && this.templates.length > 0) {
            setTimeout(() => {
                this.select(this.templates[0]);
            }, 100);
        }
    }

    select(template: GetAllMessageTemplatesResponse[0]) {
        this.$emit("select", template.id);
    }
}
