






import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    @Prop({ default: false, type: Boolean })
    open!: boolean;

    @Prop({ default: 600, type: Number })
    width!: number;

    @Prop({ default: 400, type: Number })
    height!: number;

    @Prop({ default: 200, type: Number })
    left!: number;

    @Prop({ default: 200, type: Number })
    top!: number;

    @Prop({ default: 0, type: Number })
    focusToken!: number;

    @Prop({ default: false, type: Boolean })
    noStyle!: boolean;

    windowRef: Window | null = null;
    windowLoaded = false;

    @Watch("open")
    onOpenChanged(newOpen: boolean) {
        if (newOpen) {
            this.openPortal();
        } else {
            this.closePortal();
        }
    }

    mounted() {
        if (this.open) {
            this.openPortal();
        }
        window.addEventListener("beforeunload", this.closePortal);
    }

    beforeDestroy() {
        this.closePortal();
        window.removeEventListener("beforeunload", this.closePortal);
    }

    @Watch("focusToken")
    onFocusTokenChanged() {
        if (this.windowRef) {
            this.windowRef.focus();
        }
    }

    openPortal() {
        if (this.windowRef) return;

        const { width, height, left, top } = this;

        const winHtml = `<!DOCTYPE html>
    <html>
        <head>
            <title>IntraBooking | CRS</title>
        </head>
        <body>
            <h1>... cargando contenido ...</h1>
        </body>
    </html>`;

        const winUrl = URL.createObjectURL(new Blob([winHtml], { type: "text/html" }));
        // Open a nonexistent page to replace the content later
        const windowPath = window.location.origin + "/empty.html";
        this.windowRef = window.open(windowPath, "", `width=${width},height=${height},left=${left},top=${top}`);
        // this.windowRef = window.open(winUrl, "", `width=${width},height=${height},left=${left},top=${top}`);

        if (!this.windowRef) {
            return;
        }

        this.windowRef.addEventListener("beforeunload", () => {
            this.closePortal();
        });

        this.windowRef.addEventListener("load", () => {
            this.windowLoaded = true;
            if (!this.windowRef) {
                return;
            }

            // Clear any existing content
            this.windowRef.document.body.innerHTML = "";
            this.windowRef.document.body.style.overflow = "hidden";

            this.windowRef.document.title = document.title;

            // Move the component into the window
            const app = document.createElement("div");
            app.id = "app";
            app.className = "v-application v-application--is-ltr theme--light";
            app.style.width = "100%";
            app.style.height = "100vh";
            app.appendChild(this.$el);
            this.windowRef.document.body.appendChild(app);
            this.$emit("update:open", true);
            this.$emit("opened", this.windowRef);

            (window as any).portalElement = app;

            // Clone style nodes
            if (!this.noStyle) {
                for (const el of document.head.querySelectorAll("style, link[rel=stylesheet]")) {
                    const clone = el.cloneNode(true);
                    this.windowRef.document.head.appendChild(clone);
                }
            }
        });

        this.checkClosedWindow();
    }

    closePortal() {
        if (!this.windowRef) return;

        (window as any).portalElement = undefined;
        this.windowLoaded = false;
        this.windowRef.close();
        this.windowRef = null;
        this.$emit("update:open", false);
        this.$emit("closed");
    }

    checkClosedWindow() {
        if (!this.windowRef) return;

        if (this.windowRef.closed) {
            this.closePortal();
        } else {
            setTimeout(() => this.checkClosedWindow(), 1000);
        }
    }
}
/*export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 600,
        },
        height: {
            type: Number,
            default: 400,
        },
        left: {
            type: Number,
            default: 200,
        },
        top: {
            type: Number,
            default: 200,
        },
        noStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            windowRef: null,
            windowLoaded: false,
        };
    },
    watch: {
        open(newOpen) {
            if (newOpen) {
                this.openPortal();
            } else {
                this.closePortal();
            }
        },
    },
    mounted() {
        if (this.open) {
            this.openPortal();
        }
        window.addEventListener("beforeunload", this.closePortal);
    },
    beforeDestroy() {
        this.closePortal();
        window.removeEventListener("beforeunload", this.closePortal);
    },
    methods: {
        openPortal() {
            if (this.windowRef) return;

            const { width, height, left, top } = this;

            // Open a nonexistent page to replace the content later
            const windowPath = window.location.origin + window.location.pathname + "_window";
            this.windowRef = window.open(windowPath, "", `width=${width},height=${height},left=${left},top=${top}`);
            this.windowRef.addEventListener("beforeunload", this.closePortal);

            this.windowRef.addEventListener("load", () => {
                this.windowLoaded = true;

                // Clear any existing content
                this.windowRef.document.body.innerHTML = "";

                this.windowRef.document.title = document.title;

                // Move the component into the window
                const app = document.createElement("div");
                app.id = "app";
                app.appendChild(this.$el);
                this.windowRef.document.body.appendChild(app);
                this.$emit("update:open", true);
                this.$emit("opened", this.windowRef);

                // Clone style nodes
                if (!this.noStyle) {
                    for (const el of document.head.querySelectorAll("style, link[rel=stylesheet]")) {
                        const clone = el.cloneNode(true);
                        this.windowRef.document.head.appendChild(clone);
                    }
                }
            });
        },
        closePortal() {
            if (!this.windowRef) return;

            this.windowLoaded = false;
            this.windowRef.close();
            this.windowRef = null;
            this.$emit("update:open", false);
            this.$emit("closed");
        },
    },
};
*/
