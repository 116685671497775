






































































import { GetAllImageCategoriesResponse } from "@/services/ClientInterfaces";
import { DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
    @Prop({ required: true, type: String })
    value!: string;

    categories: GetAllImageCategoriesResponse = [];

    id = "";
    name = "";
    adding = false;
    editing = false;

    async mounted() {
        await this.refresh();
    }

    async refresh() {
        this.categories = await DataClient.ImageCategories.getAll();
        Utilities.sortArrayByProperty(this.categories, "name");
    }

    beginAddition() {
        this.name = "";
        this.adding = true;
    }

    beginEdition(item: any) {
        this.id = item.id;
        this.name = item.name;
        this.editing = true;
    }

    async endAdditionOrEdition() {
        if (this.adding) {
            await DataClient.ImageCategories.add({ name: this.name });
            this.adding = false;
            await this.refresh();
        } else if (this.editing) {
            await DataClient.ImageCategories.rename({
                id: this.id,
                name: this.name,
            });
            this.editing = false;
            await this.refresh();
        }
    }

    cancelAdditionOrEdition() {
        this.adding = false;
        this.editing = false;
    }

    async remove(id: string) {
        await DataClient.ImageCategories.delete({ id });
        await this.refresh();
    }

    selectCategory(id: string) {
        this.$emit("input", id);
        this.$emit("change", id);
    }
}
