




















































































































































































































import { EventBus } from "@/components/EventBus";
import { GetAllContentElementsResponse } from "@/services/ClientInterfaces";
import {
    ContentSelectorRequest,
    ContentSelectorResponse,
    DataClient,
} from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import {
    VDialog,
    VCard,
    VBtn,
    VList,
    VListItemGroup,
    VListItem,
    VListItemContent,
    VTextField,
    VListItemAction,
    VCheckbox,
    VListItemTitle,
    VListItemSubtitle,
} from "vuetify/lib";

@Component
export default class extends Vue {
    dialog = false;
    data: any = {};

    rawItems: GetAllContentElementsResponse = [];

    multiple = false;
    selectedCategory = "";
    searchText = "";
    selection: string[] = [];

    adding = false;
    newName = "";
    newDataAdded = false;

    get items(): GetAllContentElementsResponse {
        const values = Utilities.sortAndFilter(
            this.selectedCategory
                ? this.rawItems.filter((x) => x.type === this.selectedCategory)
                : this.rawItems,
            "name",
            this.searchText,
            (item, searchText) =>
                Utilities.objectIncludes(item, ["name"], searchText)
        );

        return values;
    }

    mounted() {
        EventBus.$on(
            DataClient.ContentElements.OpenSelectorEvent,
            this.handleOpenEvent
        );
    }

    beforeDestroy() {
        EventBus.$off(
            DataClient.ContentElements.OpenSelectorEvent,
            this.handleOpenEvent
        );
    }

    reset() {
        this.dialog = false;

        this.rawItems = [];
        this.newDataAdded = false;
        this.selection = [];
        this.selectedCategory = "";
    }

    selectCategory(category: string) {
        this.selectedCategory = category;
        this.focus("searchTextField");
    }

    async refresh() {
        this.rawItems = await DataClient.ContentElements.getAll();
    }

    async handleOpenEvent(event: ContentSelectorRequest) {
        this.multiple = event.multiple === true;
        this.selectedCategory = event.type || "";
        this.searchText = "";

        await this.refresh();

        this.dialog = true;

        this.focus("searchTextField");
    }

    accept(data: ContentSelectorResponse) {
        DataClient.ContentElements.closeSelector(data);
        this.reset();
    }

    cancel() {
        DataClient.ContentElements.closeSelector();
        this.reset();
    }

    addElement() {
        this.newName = "";
        this.adding = true;
        this.focus("newNameTextField");
    }

    async endAddition() {
        if (this.newName) {
            await DataClient.ContentElements.add({
                name: {
                    es: this.newName,
                },
                type: this.selectedCategory,
            });

            this.newDataAdded = true;
            await this.refresh();

            this.adding = false;
        }
    }

    cancelAddition() {
        this.adding = false;
        this.newName = "";
    }

    selectSingleItem(item: GetAllContentElementsResponse[0]) {
        if (this.selection.length > 0) {
            // add item to selection if not already selected
            if (!this.selection.includes(item.id)) {
                this.selection.push(item.id);
            }
        } else {
            this.accept({
                newDataAdded: this.newDataAdded,
                selection: [item.id],
            });
        }
    }

    acceptSelection() {
        this.accept({
            newDataAdded: this.newDataAdded,
            selection: this.selection,
        });
    }

    focus(reference: string) {
        setTimeout(() => {
            const htmlInputElement = this.$refs[reference] as HTMLInputElement;
            if (htmlInputElement) {
                htmlInputElement.focus();
            }
        }, 100);
    }
}
