



























































































































import { EventBus } from "@/components/EventBus";
import { GetNearestMeetingPointsResponse } from "@/services/ClientInterfaces";
import { DataClient, GetMeetingPointInMapRequest, GetMeetingPointInMapResponse } from "@/services/DataClient";
import { Component, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { VBtn, VCard, VDialog } from "vuetify/lib";

@Component
export default class extends Vue {
    dialog = false;

    mode = "";
    productId = "";
    searchText = "";
    searching = false;

    // resolved
    name = "";
    address = "";

    meetingPoints: Array<{
        id: string;
        name: string;
        longitude: number;
        latitude: number;
        distanceInMeters: number;
        distanceInSeconds: number;
        distanceInMetersFormatted: string;
        distanceInSecondsFormatted: string;
    }> = [];

    zoom = 17;

    center = {
        lat: 0,
        lng: 0,
    };

    mainMarkerPosition = {
        lat: 0,
        lng: 0,
    };

    mounted() {
        EventBus.$on(DataClient.MeetingPoints.OpenMapEvent, this.handleOpenEvent);

        this.reset();
    }

    beforeDestroy() {
        EventBus.$off(DataClient.MeetingPoints.OpenMapEvent, this.handleOpenEvent);
    }

    reset() {
        this.dialog = false;

        this.mode = "";
        this.productId = "";
        this.searchText = "";
        this.searching = false;
        this.name = "";
        this.address = "";
        this.meetingPoints = [];
        this.zoom = 17;
        this.mainMarkerPosition = {
            lat: 0,
            lng: 0,
        };
    }

    async handleOpenEvent(request: GetMeetingPointInMapRequest) {
        let r: GetNearestMeetingPointsResponse | undefined = undefined;

        this.mode = request.mode;
        this.productId = request.productId || "";
        this.searching = true;

        if (request.mode === "hotel") {
            r = await DataClient.MeetingPoints.getNearest({
                hotelId: request.hotelId,
                productId: this.productId,
                maximumResultCount: 9999,
            });

            if (r && r.resolved) {
                this.name = r.resolved.name;
                this.address = r.resolved.address;
                this.meetingPoints = r.meetingPoints;

                this.center = {
                    lat: r.resolved.latitude,
                    lng: r.resolved.longitude,
                };

                this.mainMarkerPosition = {
                    lat: r.resolved.latitude,
                    lng: r.resolved.longitude,
                };
            }
        } else if (request.mode === "address") {
            this.searchAddress(request.address);
        }

        this.searching = false;
        this.dialog = true;

        // this.focus("searchTextField");
    }

    accept(data: GetMeetingPointInMapResponse) {
        DataClient.MeetingPoints.getInMap(data, true);
        this.reset();
    }

    cancel() {
        DataClient.MeetingPoints.getInMap(undefined, true);
        this.reset();
    }

    selectMeetingPoint(meetingPoint: this["meetingPoints"][0]) {
        this.accept({
            id: meetingPoint.id,
            name: meetingPoint.name,
            distanceInMeters: meetingPoint.distanceInMeters || 0,
        });
    }

    focusMeetingPoint(meetingPoint: this["meetingPoints"][0]) {
        this.center = {
            lat: 0,
            lng: 0,
        };
        this.$nextTick(() => {
            this.center = {
                lat: meetingPoint.latitude,
                lng: meetingPoint.longitude,
            };
            this.zoom = 17;
        });
    }

    focusMainMarker() {
        this.center = {
            lat: 0,
            lng: 0,
        };
        this.$nextTick(() => {
            this.center = {
                lat: this.mainMarkerPosition.lat,
                lng: this.mainMarkerPosition.lng,
            };
            this.zoom = 17;
        });
    }

    async searchAddress(address: string) {
        this.mode = "address";
        this.searching = true;

        const r = await DataClient.MeetingPoints.getNearest({
            name: address + ", Mallorca, España",
            productId: this.productId,
            maximumResultCount: 9999,
        });

        this.name = r.resolved.name;
        this.address = r.resolved.address;
        this.meetingPoints = r.meetingPoints;

        this.center = {
            lat: r.resolved.latitude,
            lng: r.resolved.longitude,
        };

        this.mainMarkerPosition = {
            lat: r.resolved.latitude,
            lng: r.resolved.longitude,
        };

        this.searchText = "";
        this.searching = false;
    }
}
