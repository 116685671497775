













































































































import { loginStore } from "@/stores/LoginStore";
import { Component, Vue } from "vue-property-decorator";

import { LogUserInResponse } from "@/services/ClientInterfaces";
import { DataClient } from "@/services/DataClient";
import { ApiError } from "@/services/BaseService";
import { BaseView } from "@/components/BaseView";
import { Utilities } from "@/services/Utilities";

@Component
export default class extends BaseView {
    username = "";
    password = "";
    showPass = false;
    errors = "";

    typeValue = "";
    typeStatus = false;
    displayTextArray = ["Bienvenido", "Welcome", "Willkommen", "Benvenuto", "Ben-vindo", "Bienvenue"];
    typingSpeed = 100;
    erasingSpeed = 100;
    newTextDelay = 2000;
    displayTextArrayIndex = 0;
    charIndex = 0;

    response: LogUserInResponse | null = null;

    created() {
        setTimeout(this.typeText, this.newTextDelay + 200);
        Utilities.focus(this, "username");
    }

    typeText() {
        if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
            if (!this.typeStatus) this.typeStatus = true;
            this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex);
            this.charIndex += 1;
            setTimeout(this.typeText, this.typingSpeed + Math.random() * this.typingSpeed);
        } else {
            this.typeStatus = false;
            setTimeout(this.eraseText, this.newTextDelay);
        }
    }
    eraseText() {
        if (this.charIndex > 0) {
            if (!this.typeStatus) this.typeStatus = true;
            this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(0, this.charIndex - 1);
            this.charIndex -= 1;
            setTimeout(this.eraseText, this.erasingSpeed);
        } else {
            this.typeStatus = false;
            this.displayTextArrayIndex += 1;
            if (this.displayTextArrayIndex >= this.displayTextArray.length) this.displayTextArrayIndex = 0;
            setTimeout(this.typeText, this.typingSpeed + 1000);
        }
    }

    async login() {
        if (!this.username || !this.password) {
            return;
        }

        try {
            const r = await DataClient.Users.logIn({
                username: this.username,
                password: this.password,
            });

            loginStore().logIn(r);
        } catch (e) {
            if (e instanceof ApiError) {
                this.errors = e.errorMessage;
            }
        }
    }
}
