import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import transfersRoutes from "./TransfersRoutes";
import excursionsRoutes from "./ExcursionsRoutes";
import websiteRoutes from "./WebsitesRoutes";
import globalRoutes from "./GlobalRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...transfersRoutes,
    ...excursionsRoutes,
    ...websiteRoutes,
    ...globalRoutes
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        const title = (to.meta && to.meta.title) ? to.meta.title : "";
        if (title) {
            document.title = title + " | CRS | Intrabooking";
        } else {
            document.title = "CRS | Intrabooking";
        }
    });
});

export default router;
