











































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    $refs!: {
        editToolBarInput: HTMLInputElement;
    };

    editing = false;
    editText = "";

    @Prop({ default: "" })
    icon?: string;

    @Prop({ default: "Sin título" })
    title?: string;

    @Prop({ default: null, type: String, required: false })
    subtitle?: string;

    @Prop({ default: null, type: String, required: false })
    organization?: string;

    @Prop({ default: "Pulsa para cambiar el nombre" })
    tooltip?: string;

    @Prop({ default: "", type: String })
    model!: string;

    @Prop({ default: false, type: Boolean })
    editable?: boolean;

    @Prop({ default: false, type: Boolean })
    saving?: boolean;

    // @Watch("searchText")
    // updateSearchText(value: string) {
    //     this.$emit("searchTextChanged", value);
    // }

    get hasSlot(): boolean {
        const name = "default";
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }

    startEditing() {
        this.editText = this.model || "";
        this.editing = true;

        this.$nextTick(() => {
            this.$refs?.editToolBarInput?.focus();
        });
    }

    acceptEditedText() {
        if (this.editText !== this.model) {
            this.$emit("modelChanged", this.editText);
        }

        this.editing = false;
    }

    cancelEditing() {
        this.editing = false;
    }
}
