








































import { ApiError } from "@/services/BaseService";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class extends Vue {
    dialog = false;
    resolve: ((value: boolean) => void) | null = null;
    reject: ((value: boolean) => void) | null = null;
    message: string | null = null;
    title: string | null = null;

    open(title: string, error: Error | ApiError | string) {
        this.dialog = true;
        this.title = title;
        if (error instanceof ApiError) {
            this.message = error.errorMessage;
        } else if (error instanceof Error) {
            this.message = error.message;
        } else {
            this.message = error;
        }

        this.message = this.message || "Error desconocido";

        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    agree() {
        if (this.resolve) {
            this.resolve(true);
        }

        this.dialog = false;
    }

    cancel() {
        if (this.resolve) {
            this.resolve(false);
        }

        this.dialog = false;
    }
}
