










import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {

    @Prop({ required: true })
    model?: string;

}
