







































































































import { EventBus } from "@/components/EventBus";
import { GetAllImageCategoriesResponse, GetAllImagesResponse } from "@/services/ClientInterfaces";
import { DataClient, GalleryRequest } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import ImageCategoryList from "@/views/Images/Components/ImageCategoryList.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        ImageCategoryList,
    },
})
export default class extends Vue {
    dialog = false;

    selectedCategory = "";
    multiple = false;

    categories: GetAllImageCategoriesResponse = [];
    images: GetAllImagesResponse = [];

    get filteredImages(): GetAllImagesResponse {
        let base: GetAllImagesResponse;

        if (this.selectedCategory === "") {
            base = [...this.images];
        } else if (this.selectedCategory === "uncategorized") {
            base = this.images.filter((x) => x.format === "loading" || !x.categoryId);
        } else if (this.selectedCategory === "unused") {
            base = this.images.filter((x) => x.format === "loading" || x.referenceCount === 0);
        } else {
            base = this.images.filter((x) => x.format === "loading" || x.categoryId === this.selectedCategory);
        }

        Utilities.sortArrayByProperty(base, "-creationDate");

        return base;
    }

    mounted() {
        EventBus.$on(DataClient.GalleryService.OpenGalleryEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.GalleryService.OpenGalleryEvent, this.handleOpenEvent);
    }

    reset() {
        this.dialog = false;
        this.images = [];
    }

    selectCategory(category: string) {
        this.selectedCategory = category;
    }

    async refresh() {
        this.images = await DataClient.Images.getAll();
    }

    async leerImagen(e: Event) {
        const files = (e.target as HTMLInputElement)?.files;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e && e.target && e.target.result) {
                        const result = e.target.result as string;
                        const base64 = result.split(",")[1];
                        this.uploadImage(file.name, base64);
                    }
                };

                reader.readAsDataURL(file);
            }
        }
    }

    selectImage(image: GetAllImagesResponse[number]) {
        this.accept({
            selection: [image.id],
        });
    }

    async uploadImage(name: string, base64Data: string) {
        const categoryId = ["", "uncategorized", "unused"].includes(this.selectedCategory) ? "" : this.selectedCategory;

        const image: GetAllImagesResponse[0] = {
            id: Math.random().toString(),
            name: name,
            width: 0,
            height: 0,
            format: "loading",
            hasMissingContent: true,
            categoryId,
            creationDate: "",
        } as GetAllImagesResponse[0];

        this.images.unshift(image);

        const response = await DataClient.Images.add({
            name: {
                es: name,
            },
            categoryId,
            data: base64Data,
        });

        if (response) {
            image.id = response.id;
            image.width = response.width;
            image.height = response.height;
            image.format = response.format;
            image.hasMissingContent = response.hasMissingContent;
            image.categoryId = response.categoryId;
            image.referenceCount = response.referenceCount;
            image.creationDate = response.creationDate;
        }
    }

    async handleOpenEvent(event: GalleryRequest) {
        this.multiple = event.multiple || false;
        this.selectedCategory = event.category || "";

        await this.refresh();

        this.dialog = true;
    }

    accept(data: any) {
        DataClient.GalleryService.close(data || true);
        this.reset();
    }

    cancel() {
        DataClient.GalleryService.close();
        this.reset();
    }
}
