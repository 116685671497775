
















































































































































































































import { EventBus } from "@/components/EventBus";
import { ConfirmationDialogRequest, DataClient } from "@/services/DataClient";
import { Utilities } from "@/services/Utilities";
import { Component, Vue } from "vue-property-decorator";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { VBtn, VCard, VDialog } from "vuetify/lib";

interface Question {
    text: string;
    checked: boolean;
}

interface Input {
    type?: "text" | "date" | "text-area" | "select" | "number" | "switch" | "time-range";
    label: string;
    value: string | boolean;
    items?: Array<{ id: string; name: string }>;
    required?: boolean;
    rows?: number;
}

interface DebugItem {
    key: string;
    value: string;
}

@Component
export default class extends Vue {
    dialog = false;

    title = "";
    message = "";
    messages: string[] = [];
    warningMessage = "";
    questions: Question[] = [];
    inputs: Input[] = [];
    nocancel = false;
    width = "";
    color = "";
    icon = "";
    iconColor = "";
    buttonColor = "";
    cancelButtonColor = "";
    warningMessageChecked = false;
    debugItems: DebugItem[] = [];
    acceptButtonText = "Aceptar";
    cancelButtonText = "Cancelar";
    attach: any = null;
    persistent = false;

    mounted() {
        EventBus.$on(DataClient.Dialogs.OpenConfirmationDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Dialogs.OpenConfirmationDialogEvent, this.handleOpenEvent);
    }

    reset() {
        this.dialog = false;
    }

    get disableAccept() {
        if (this.warningMessage && !this.warningMessageChecked) {
            return true;
        }

        for (const input of this.inputs) {
            if (!input.required) {
                continue;
            }

            if (input.type === "number" && (input.value === undefined || input.value === null || input.value === "")) {
                return true;
            } else if (input.type !== "number" && !input.value) {
                return true;
            }
        }

        // if (this.inputs.some((i) => i.required && !i.value)) {
        //     return true;
        // }

        return false;
    }

    async handleOpenEvent(event: ConfirmationDialogRequest) {
        this.title = event.title;
        this.message = this.buildHtmlFromRawMessage(event.message || "");
        this.messages = (event.messages || []).map((m) => this.buildHtmlFromRawMessage(m));
        this.warningMessage = event.warningMessage || "";
        this.questions = (event.questions || []).map((q) => ({ text: q.startsWith("*") ? q.substring(1) : q, checked: q.startsWith("*") }));
        this.inputs = (event.inputs || []).map((i) => ({
            type: i.type || "text",
            label: i.label,
            value: i.value || "",
            items: i.items || [],
            required: i.required || false,
            rows: i.rows || 5,
        }));
        this.nocancel = event.nocancel || false;
        this.color = event.color || "#2B3848";
        this.width = event.width || "600";
        this.icon = event.icon || "";
        this.iconColor = event.iconColor || "orange darken-2";
        this.buttonColor = event.buttonColor || "primary";
        this.cancelButtonColor = event.cancelButtonColor || "gray";
        this.warningMessageChecked = false;

        if (event.debug) {
            this.debugItems = Object.keys(event.debug).map((k) => ({ key: k, value: event.debug[k] }));
        } else {
            this.debugItems = [];
        }

        this.acceptButtonText = event.acceptButtonText || "Aceptar";
        this.cancelButtonText = event.cancelButtonText || "Cancelar";
        this.attach = event.attach || undefined;
        this.persistent = event.persistent || false;

        this.dialog = true;

        if (this.inputs.length > 0) {
            Utilities.focus(this, "input-0");
        }
    }

    accept() {
        const inputs: string[] = [];
        for (const input of this.inputs) {
            if (input.type === "date") {
                inputs.push(input.value ? Utilities.formatDateToYYYYMMDD(input.value as string) : "");
            } else if (input.type === "switch") {
                inputs.push(input.value ? "true" : "false");
            } else {
                inputs.push((input.value as string) || "");
            }
        }

        const questions = this.questions.map((q) => q.checked);

        if (this.inputs.length > 0 && this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestionsAndInputs([true, questions, inputs], true);
        } else if (this.inputs.length > 0) {
            DataClient.Dialogs.confirmationWithInputs([true, ...inputs], true);
        } else if (this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestions([true, ...questions], true);
        } else {
            DataClient.Dialogs.confirmation(true, true);
        }

        this.reset();
    }

    cancel(fromEsc: boolean) {
        if (fromEsc && this.persistent) {
            return;
        }

        if (this.inputs.length > 0 && this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestionsAndInputs([false, [], []], true);
        } else if (this.inputs.length > 0) {
            DataClient.Dialogs.confirmationWithInputs([false, ...this.inputs.map((i) => "")], true);
        } else if (this.questions.length > 0) {
            DataClient.Dialogs.confirmationWithQuestions([false, ...this.questions.map((q) => false)], true);
        } else {
            DataClient.Dialogs.confirmation(false, true);
        }

        this.reset();
    }

    buildHtmlFromRawMessage(message: string): string {
        return message.replace(/\n/g, "<br/>");
    }

    isNumber(evt: KeyboardEvent): void {
        const keysAllowed: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
        const keyPressed: string = evt.key;

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault();
        }
    }

    getInputDate(input: Input): string {
        if (!input.value) {
            return "";
        }

        return Utilities.formatDateToYYYYMMDD(input.value as string);
    }

    getTimeFromRange(input: Input, slot: number): string {
        if (!input.value) {
            return "";
        }

        const slots = (input.value as string).split("-");
        return slots[slot] || "";
    }

    setTimeInRange(input: Input, slot: number, time: string): void {
        const slot1 = slot === 0 ? time : this.getTimeFromRange(input, 0);
        const slot2 = slot === 1 ? time : this.getTimeFromRange(input, 1);

        input.value = `${slot1}-${slot2}`;
    }

    setInputDate(input: Input, date: string) {
        input.value = Utilities.formatDateToDDMMYYYY(date);
    }

    containsLink(value: string): boolean {
        return !value.includes("src=") && value.includes("https://");
    }

    extractLinkFromString(value: string): string {
        const matches = value.match(/(https?:\/\/[^\s]+)/g);
        return matches ? matches[0] : "";
    }

    copyLinkFromMessageIntoClipboard(value: string): void {
        const link = Utilities.extractLinkFromHTML(value) || this.extractLinkFromString(value);
        Utilities.copyToClipboard(link);
        DataClient.Dialogs.toast({ title: "Enlace copiado", message: "El enlace ha sido copiado al portapapeles.", color: "gray darken-2" });
    }

    openLinkFromMessageInNewTab(value: string): void {
        const link = Utilities.extractLinkFromHTML(value) || this.extractLinkFromString(value);
        window.open(link, "_blank");
    }
}
