











































































































































































































import { EventBus } from "@/components/EventBus";
import { OpenCrmRequest, DataClient } from "@/services/DataClient";
import { Component, Vue, Watch } from "vue-property-decorator";
import { VBtn, VCard, VDialog } from "vuetify/lib";
import CrmTemplateList from "@/views/Crm/CrmTemplateList.vue";
import { BaseView } from "@/components/BaseView";
import ConversationView from "@/views/Crm/ConversationView.vue";

@Component({
    components: {
        CrmTemplateList,
        ConversationView,
    },
})
export default class extends BaseView {
    dialog = false;

    sourceLanguage = "es";
    targetLanguage = "en";
    translating = false;

    languageNames = [
        { code: "es", name: "Español" },
        { code: "en", name: "Inglés" },
        { code: "fr", name: "Francés" },
        { code: "de", name: "Alemán" },
        { code: "it", name: "Italiano" },
        { code: "cz", name: "Checo" },
        { code: "pt", name: "Portugués" },
        { code: "ru", name: "Ruso" },
        { code: "pl", name: "Polaco" },
    ];

    messageTemplateId = "";
    messageTemplateType = "";
    autoSelectFirst = false;

    target = "customer";
    sendEmail = false;
    sendWhatsApp = false;

    bookingId = "";
    prebookingId = "";
    transferBookingId = "";
    conversationId = "";

    productId = "";
    meetingPointId = "";
    hotelId = "";

    agencyId = "";
    agency = "";
    agencyImageId = "";
    reference = "";
    date = "";
    activity = "";
    meetingPoint = "";
    pickupTime = "";
    language = "";
    name = "";
    hotel = "";
    email = "";
    phone = "";
    comments = "";
    adults = 0;
    children = 0;
    babies = 0;

    customerEmail = "";
    customerPhone = "";
    subject = "";

    text = "";

    conversationRefreshToken = 0;
    sending = false;

    mounted() {
        EventBus.$on(DataClient.Crm.OpenCrmDialogEvent, this.handleOpenEvent);
    }

    beforeDestroy() {
        EventBus.$off(DataClient.Crm.OpenCrmDialogEvent, this.handleOpenEvent);
    }

    async handleOpenEvent(event: OpenCrmRequest) {
        this.messageTemplateId = "";
        this.messageTemplateType = event.messageTemplateType || "";
        this.autoSelectFirst = event.messageTemplateType ? true : false;

        this.productId = event.productId || "";
        this.meetingPointId = event.meetingPointId || "";
        this.hotelId = event.hotelId || "";
        this.pickupTime = event.pickupTime || "";

        if (event.productId) {
            this.bookingId = "";
            this.prebookingId = "";
            this.transferBookingId = "";
            this.conversationId = "";
            this.agencyId = "";
            this.agency = "";
            this.reference = "";
            this.date = "";
            this.activity = "";
            this.meetingPoint = "";
            this.language = "es";
            this.name = "";
            this.hotel = "";
            this.customerEmail = "";
            this.customerPhone = "";
            this.comments = "";

            this.target = "customer";
            this.sendEmail = true;
            this.sendWhatsApp = true;
        } else if (event.bookingId) {
            const booking = await DataClient.Bookings.get({ id: event.bookingId });

            this.bookingId = event.bookingId;
            this.prebookingId = "";
            this.transferBookingId = "";
            this.conversationId = booking.conversationId;

            this.agencyId = booking.agencyId;
            this.agency = booking.agencyName;
            this.reference = booking.reference;
            this.date = booking.date;
            this.activity = booking.productName;
            this.meetingPoint = booking.meetingPointName;
            this.pickupTime = booking.pickupTime;
            this.language = booking.language;
            this.name = booking.customerName;
            this.hotel = booking.hotelName;
            this.customerEmail = booking.customerEmail;
            this.customerPhone = booking.customerPhone;
            this.comments = booking.comments;
            this.target = "customer";
            this.sendEmail = false;
            this.sendWhatsApp = false;
        } else if (event.transferBookingId) {
            const booking = await DataClient.TransferBookings.get({ id: event.transferBookingId });

            this.bookingId = "";
            this.prebookingId = "";
            this.transferBookingId = event.transferBookingId;
            this.conversationId = "";

            this.agencyId = booking.agencyId;
            this.agency = booking.agencyName;
            this.reference = booking.reference;
            this.date = booking.date;
            this.activity = "";
            this.meetingPoint = "";
            this.pickupTime = "";
            this.language = "de";
            this.name = booking.rooms[0]?.customers[0]?.name;
            this.hotel = booking.rooms[0].hotelName;
            this.customerEmail = "test@gmail.com";
            this.customerPhone = "+34651610173";
            this.comments = booking.comments;
            this.target = "customer";
            this.sendEmail = false;
            this.sendWhatsApp = false;
        } else if (event.prebookingId) {
            const booking = await DataClient.Prebookings.get({ id: event.prebookingId });

            this.bookingId = "";
            this.prebookingId = event.prebookingId;
            this.conversationId = booking.conversationId;

            this.agencyId = booking.agencyId;
            this.agency = booking.agencyName;
            this.reference = booking.reference;
            this.date = booking.date;
            this.activity = booking.productName;
            this.meetingPoint = booking.meetingPointName;
            this.pickupTime = booking.pickupTime;
            this.language = booking.language;
            this.name = booking.customerName;
            this.hotel = booking.hotelName;
            this.customerEmail = booking.customerEmail;
            this.customerPhone = booking.customerPhone;
            this.comments = booking.comments;

            this.adults = booking.adults;
            this.children = booking.children;
            this.babies = booking.babies;

            this.target = "customer";
            this.sendEmail = false;
            this.sendWhatsApp = false;
        }

        this.email = this.customerEmail;
        this.phone = this.customerPhone;

        if (this.agencyId && !event.transferBookingId) {
            const agency = await DataClient.Agencies.get({ id: this.agencyId });

            this.agencyImageId = agency?.imageId;
        }

        this.subject = "";
        this.text = "";

        if (this.language !== "es") {
            this.targetLanguage = this.language;
        }

        this.dialog = true;
    }

    accept() {
        this.dialog = false;

        DataClient.Crm.open(true, true);
    }

    cancel() {
        this.dialog = false;

        DataClient.Crm.open(false, true);
    }

    setCustomerTarget() {
        this.target = "customer";

        this.email = this.customerEmail;
        this.phone = this.customerPhone;

        this.sendEmail = false;
        this.sendWhatsApp = false;
    }

    async setAgencyTarget() {
        if (this.transferBookingId) {
            this.target = "agency";

            this.email = "info@alltours.de";
            this.phone = "+34651610173";

            this.sendEmail = false;
            this.sendWhatsApp = false;
        } else {
            const agency = await DataClient.Agencies.get({ id: this.agencyId });

            this.email = agency.operationalInformation.email;
            this.phone = agency.operationalInformation.phone;

            this.target = "agency";

            this.sendEmail = false;
            this.sendWhatsApp = false;
        }
    }

    @Watch("language")
    async onLanguageChanged() {
        if (this.messageTemplateId) {
            await this.renderTemplate();
        }
    }

    async selectTemplate(id: string) {
        this.messageTemplateId = id;
        await this.renderTemplate();
    }

    async renderTemplate() {
        const template = await DataClient.MessageTemplates.render({
            id: this.messageTemplateId,
            language: this.target === "agency" ? "es" : this.language,
            productId: this.productId,
            meetingPointId: this.meetingPointId,
            hotelId: this.hotelId,
            pickupTime: this.pickupTime,
            bookingId: this.bookingId,
            prebookingId: this.prebookingId,
            transferBookingId: this.transferBookingId,
        });

        this.text = template.content;
    }

    async sendMessage() {
        this.sending = true;

        await this.secureExecution(async () => {
            const r = await DataClient.Conversations.sendMessage({
                bookingId: this.bookingId,
                prebookingId: this.prebookingId,
                subject: this.subject || "",
                text: this.text,
                email: this.sendEmail ? this.email : "",
                whatsAppPhoneNumber: this.sendWhatsApp ? this.phone : "",
            });

            this.text = "";

            this.conversationId = r.id;
            this.conversationRefreshToken++;
        });

        this.sending = false;

        if (!this.agencyId) {
            this.cancel();
        }
    }

    get valid() {
        if (!this.email && !this.phone) {
            return false;
        }

        if (this.sendEmail && this.email && !this.emailIsValid(this.email)) {
            return false;
        }

        if (this.sendEmail && this.email && !this.agency && !this.subject) {
            return false;
        }

        if (!this.text) {
            return false;
        }

        return true;
    }

    emailIsValid(email: string) {
        const replaced = (email || "").replaceAll(";", ",");
        const emails = replaced.split(",");

        for (const singleEmail of emails) {
            const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(singleEmail);
            if (!valid) {
                return false;
            }
        }

        return true;
    }

    resend(text: string) {
        this.text = text;
    }

    swapLanguages() {
        const source = this.sourceLanguage;
        this.sourceLanguage = this.targetLanguage;
        this.targetLanguage = source;
    }

    async translate() {
        this.translating = true;
        const r = await DataClient.TextTranslation.translate({
            text: this.text,
            sourceLanguage: this.sourceLanguage,
            targetLanguage: this.targetLanguage,
        });
        this.text = r.text;
        this.translating = false;
    }
}
