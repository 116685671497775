













import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {

    @Prop({ default: "" })
    name?: string;

    @Prop({ default: "" })
    value?: string;

    get positiveName() {
        return this.name;
    }

    get negativeName() {
        return "-" + this.name;
    }

    get icon() {
        if (this.value === this.positiveName) {
            return "mdi-chevron-up";
        } else if (this.value === this.negativeName) {
            return "mdi-chevron-down";
        } else {
            return "mdi-chevron-down";
        }
    }

    get color() {
        if (this.value === this.positiveName) {
            return "";
        } else if (this.value === this.negativeName) {
            return "";
        } else {
            return "transparent";
        }
    }

    setNewOrdering() {
        if (this.value === this.positiveName) {
            this.$emit("input", this.negativeName);
        } else if (this.value === this.negativeName) {
            this.$emit("input", "");
        } else {
            this.$emit("input", this.positiveName);
        }
    }

}
