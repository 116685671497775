import Vue from "vue";

// Vue.filter("timestamp", function (value: string) {
//     if (!value) return "";

//     // convert a timestamp to a date in format DD/MM/YYYY a las HH:mm with padding 0
//     const date = new Date(value);
//     const day = date.getDate();
//     const month = date.getMonth() + 1;
//     const year = date.getFullYear();
//     const hours = date.getHours();
//     const minutes = date.getMinutes();

//     return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year} a las ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
// });


export class Filters {

    public static timestamp(value: string): string {
        if (!value) return "";

        // convert a timestamp to a date in format DD/MM/YYYY a las HH:mm with padding 0
        const date = new Date(value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year} a las ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }

}
