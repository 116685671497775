







































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {

    $refs!: {
        editToolBarInput: HTMLInputElement;
    };

    editing = false;
    editText = "";

    @Prop({ default: "Pulsa para cambiar el nombre" })
    tooltip?: string;

    @Prop({ required: true })
    model?: string;

    startEditing() {
        this.editText = this.model || "";
        this.editing = true;

        this.$nextTick(() => {
            this.$refs?.editToolBarInput?.focus();
        });
    }

    acceptEditedText() {
        if (this.editText !== this.model) {
            this.$emit("modelChanged", this.editText);
        }

        this.editing = false;
    }

    cancelEditing() {
        this.editing = false;
    }
}
