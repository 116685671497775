























import { Utilities } from "@/services/Utilities";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

export type LanguageList = string[];
export type LocalizableString = { [key: string]: string };

type LocalData = {
    language: string;
    label: string;
    value: string;
}[];

@Component
export default class extends Vue {

    editorId = "";
    localDataCopy: LocalData = [];

    @Prop({ default: ["es", "en"] })
    languages?: LanguageList;

    @Prop({ default: {} })
    value?: LocalizableString;

    @Prop()
    label?: string;

    @Prop({ default: false, type: Boolean })
    autoFocus?: boolean;

    get localData() {
        this.localDataCopy = this.localizableStringToLocalData(this.value || {});
        return this.localDataCopy;
    }

    mounted() {
        this.editorId = "editor_" + (this as any)._uid;

        if (this.autoFocus) {
            this.focus();
        }
    }

    focus() {
        const refName = `${this.editorId}_${this.languages![0]}`;

        // setTimeout(() => {
        //     const htmlInputElement = (this.$refs[refName] as HTMLInputElement);
        //     if (htmlInputElement && htmlInputElement.focus) {
        //         htmlInputElement.focus();
        //     }
        // }, 100);

        // this.$nextTick(() => {
        //     const theElement = this.$refs[refName] as HTMLElement;
        //     const input = theElement.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
        //     if (input) {
        //         setTimeout(() => {
        //             (input as HTMLElement).focus()
        //         }, 0)
        //     }
        // });
    }

    refresh(language: string, value: string) {
        // console.log("refresh", language, value);

        this.localDataCopy = this.localDataCopy.map(item => {
            if (item.language === language) {
                item.value = value;
            }
            return item;
        });

        this.$emit("input", this.localDataToLocalizableString(this.localDataCopy));
    }

    localizableStringToLocalData(value: LocalizableString): LocalData {
        const result: LocalData = [];
        for (const language of this.languages || []) {
            result.push({
                language,
                label: this.label ? `${this.label} (${Utilities.getLanguageName(language)})` : Utilities.getLanguageName(language),
                value: value[language] || ""
            });
        }

        return result;
    }

    localDataToLocalizableString(value: LocalData): LocalizableString {
        const result: LocalizableString = {};
        for (const item of value) {
            result[item.language] = item.value;
        }

        return result;
    }

}
